/**
 * 负向信息组件 GoodsNegativeInfo
 * GoodsNegativeInfo 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }) {
  const negativeInfo = data.value.aggregateProductBusiness?.negativeInfo || {}
  const negativeInfos = []
  if (negativeInfo.notPointsText) {
    negativeInfos.push({
      text: negativeInfo.notPointsText || ''
    })
  }
  if (negativeInfo.notCouponText) {
    negativeInfos.push({
      text: negativeInfo.notCouponText || ''
    })
  }
  if (negativeInfo.notReturnText) {
    negativeInfos.push({
      text: negativeInfo.notReturnText || ''
    })
  }
  return {
    isShow: negativeInfos.length > 0,
    negativeInfos,
  }
}
