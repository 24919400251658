import DiscountNormalTag from './DiscountNormalTag.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useDiscountNormalTag = createHelper('DiscountNormalTag', getProps)

export {
  DiscountNormalTag,
  useDiscountNormalTag,
}
