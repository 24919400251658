import DiscountLimiteTag from './DiscountLimiteTag.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useDiscountLimiteTag = createHelper('DiscountLimiteTag', getProps)

export {
  DiscountLimiteTag,
  useDiscountLimiteTag,
}
