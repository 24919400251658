import { hexToRgb } from '@shein/common-function'
import { getTimestampFormProductTags } from '../../utils/getTimestampFormProductTags'

/**
 * DiscountNormalTag 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }) {
  const apb = data.value.aggregateProductBusiness || {}
  const { estimatedData = {} } = apb

  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1'
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  const isShowMask = (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem

  const text = estimatedData.estimatedTip
  const estimatedPricePopUp = apb.estimatedPricePopUp && JSON.parse(JSON.stringify(apb.estimatedPricePopUp))
  if (estimatedPricePopUp?.subInfo?.items) estimatedPricePopUp.subInfo.items = estimatedPricePopUp?.subInfo.items.filter(item => item.benefitType !== 'estimatedAmount')

  let isShowEndTimestamp = !!estimatedData.estimatedPriceEndTimeStamp
  if (isShowEndTimestamp) {
    isShowEndTimestamp = !getTimestampFormProductTags(apb.productTags)
  }

  const textColor = estimatedData.textColor

  return {
    isShow: !estimatedData.style && !!apb.discountSubscript && !isShowEndTimestamp && !!text,
    isShowMask,
    isHover: !isShowMask && !!estimatedPricePopUp,
    text,
    estimatedPricePopUp,

    textColor,
    lineColor: hexToRgb({ hex: textColor, opa: '0.4' }),
    bgColor: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',
    maxWidth: '100%'
  }
}
